<template>
  <v-container fluid grid-list-md>

    <v-dialog v-model="dialog" width="auto">
      <v-card>
        <v-card-text>
          <br>
          <section v-if="success">
            Das Absenden des Formulars war erfolgreich.
          </section>
          <section v-else>
            Beim Absenden des Formulars ist ein Fehler aufgetreten, bitte wenden Sie sich an die Administration.
          </section>
        </v-card-text>
        <v-card-actions v-if="success">
          <v-btn color="success" block @click="closeDialog()">Schließen</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-btn color="error" block @click="closeDialog()">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card max-width="1200" class="mx-auto pa-5">

      <v-card-subtitle v-if="(competitionItems.length == 0)" class="pa-5">
        Aktuell sind keine Wettspiele vorhanden, für die Abrechnungen eingereicht werden können.
      </v-card-subtitle>

      <v-card-subtitle v-else-if="(competitionItems.length > 0)" class="pa-5">
        Für folgende Wettspiele können Abrechnungen eingereicht werden:
      </v-card-subtitle>

      <v-form v-if="(competitionItems.length > 0)" ref="invoiceForm" v-model="isFormValid"
        @submit.prevent="sendInvoiceForm" class="pa-5">
        <!-- <v-select label="Austragungsjahr" :items="['2022', '2023']"></v-select> -->
        <v-select label="Wettspiel" v-model="competition" :rules="competitionRules" :items="competitionItems"></v-select>

        <v-row>
          <v-col class="d-flex align-center" cols="12" sm="3">
            <v-text-field v-model="competitionStartDate" label="Turnier Startdatum" readonly />
          </v-col>

          <v-col class="d-flex align-center" cols="12" sm="3">
            <v-text-field v-model="competitionEndDate" label="Turnier Enddatum" readonly />
          </v-col>
          <v-col class="d-flex align-center" cols="12" sm="3">
            <v-text-field v-model="username" label="Benutzername" readonly></v-text-field>
          </v-col>

          <v-col class="d-flex align-center" cols="12" sm="3">
            <v-text-field v-model="email" label="E-Mail" readonly></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center" cols="12" sm="6">
            <v-text-field v-model="mileageAllowanceInput" :rules="mileageAllowanceInputRule" type="number"
              label="Gefahrene Kilometer" />
          </v-col>

          <v-col class="d-flex align-center" cols="12" sm="6">
            <v-text-field v-model="mileageAllowanceOutputAsString" type="text"
              label="Kilometerpauschale Gesamt (km * 0,30€)" readonly></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center" cols="12" sm="6">
            <v-select v-bind="refereeAllowanceInput" :rules="refereeAllowanceInputRule" @change="propsOnChange($event)"
              label="Anzahl Spielleiterpauschalen"></v-select>
          </v-col>

          <v-col class="d-flex align-center" cols="12" sm="6">
            <v-text-field v-model="refereeAllowanceOutputAsString" type="text"
              label="Spielleiterpauschalen Gesamt (Anzahl * 80€)" readonly></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center" cols="12" sm="6">
            <v-text-field v-model="sumExpenses" :rules="sumExpensesRules" type="text"
              label="Summe aller Spesen (optional)" />
          </v-col>

          <v-col class="d-flex align-center" cols="12" sm="6">
            <v-file-input show-size label="Quittungen der Spesen hochladen (optional)" v-model="images"
              :rules="imageRules" multiple @change="processImages"></v-file-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center" cols="12" sm="6">
            <v-text-field v-model="betragAsString" :rules="betragRules" type="text" label="Gesamtbetrag" readonly />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center" cols="12" sm="3">
            <v-text-field v-model="depositor" :rules="depositorInputRule" label="Kontoinhaber" />
          </v-col>

          <v-col class="d-flex align-center" cols="12" sm="3">
            <v-text-field v-model="bank" :rules="bankInputRule" label="Bank" />
          </v-col>
          <v-col class="d-flex align-center" cols="12" sm="3">
            <v-text-field v-model="iban" :rules="ibanInputRule" label="IBAN"></v-text-field>
          </v-col>

          <v-col class="d-flex align-center" cols="12" sm="3">
            <v-text-field v-model="bic" :rules="bicInputRule" label="BIC"></v-text-field>
          </v-col>
        </v-row>

        <v-text-field label="Kommentar (optional)" v-model="comment" :rules="commentRules" optional></v-text-field>
        <v-btn block type="submit" class="mt-2" :disabled="!isFormValid">
          Absenden
        </v-btn>

      </v-form>
    </v-card>
  </v-container>
</template>

<script>
// import rules from "../../helpers/rules";
import axios from "axios";

export default {
  name: "Abrechnung",
  data() {
    return {
      name: "Abrechnung",
      backend: process.env.VUE_APP_STRAPI_API_URL,
      isFormValid: false,
      competitions: [],
      mileageAllowance: null,
      mileageAllowanceInput: null,
      mileageAllowanceInputRule: [
        (value) => !!value || "Diese Angabe ist erforderlich",
      ],
      mileageAllowanceOutput: 0,
      mileageAllowanceOutputAsString: "",
      refereeAllowance: null,
      refereeAllowanceInputRule: [],
      //   (value) => !!value || "Diese Angabe ist erforderlich",
      // ],
      depositor: null,
      depositorInputRule: [
        (value) => !!value || "Diese Angabe ist erforderlich",
      ],
      bank: null,
      bankInputRule: [
        (value) => !!value || "Diese Angabe ist erforderlich",
      ],
      iban: null,
      ibanInputRule: [
        (value) => !!value || "Diese Angabe ist erforderlich",
      ],
      bic: null,
      bicInputRule: [
        (value) => !!value || "Diese Angabe ist erforderlich",
      ],
      anzahlAnTagen:0,
      refereeAllowanceOutput: 0,
      refereeAllowanceOutputAsString: "",
      competition: null,
      // competitionItems: ["Platzhalter0", "Platzhalter1", "Platzhalter2"],
      competitionRules: [(value) => !!value || "Diese Angabe ist erforderlich"],
      competitionId: null,
      sumExpenses: undefined,
      sumExpensesRules: [],
      comment: null,
      commentRules: [
        // (value) => !!value || "Diese Angabe ist erforderlich",
      ],
      // betrag: null,
      betragRules: [
        // (value) => !!value || "Diese Angabe ist erforderlich",
      ],
      competitionStartDate: null,
      competitionEndDate: null,
      // props: [],
      user: null,
      userId: null,
      username: null,
      email: null,

      images: [],
      imageRules: [
        // v => !!v || 'File is required',
        // v => (v && v.length > 0) || 'Es muss mindestens eine Quittung hochgeladen werden',
        v => !v.some((v) => v.size > 2000000) ||
          "Die große eines einzelnen Bildes sollte nicht mehr als 2MB betragen"
      ],
      success: true,
      dialog: false,
    };
  },
  computed: {
    competitionItems() {
      let result = [];

      if(this.user?.ist_GVNRW_Mitarbeiter){
        return [];
      }

      this.competitions.forEach((element) => {
        let userHasAlreadyRated = false;
        if (element.abrechnungens && element.abrechnungens.length >= 1) {
          element.abrechnungens.forEach((abrechnung) => {
            if (abrechnung.users_permissions_user == this.userId) {
              userHasAlreadyRated = true;
            }
          });
        }
        if (!userHasAlreadyRated) result.push(element.name);
      });
      // if (result.length == 0) {
      //   result.push("Aktuell ist kein Wettspiel verfügbar, dass abgerechnet werden kann.")
      // }
      return result.sort();
    },
    refereeAllowanceInput() {
      return {
        label: "Select",
        items: [0, 1, 2, 3, 4],
      };
    },
    betrag() {
      return (
        parseFloat(this.mileageAllowanceOutput) +
        parseFloat(this.refereeAllowanceOutput) +
        (parseFloat(this.sumExpenses) || 0)
      ).toFixed(2);
    },
    betragAsString() {
      return (
        (
          parseFloat(this.mileageAllowanceOutput) +
          parseFloat(this.refereeAllowanceOutput) +
          (parseFloat(this.sumExpenses) || 0)
        ).toFixed(2) + " €"
      );
    },
  },
  async created() {
    const axios = require("axios");

    let yourDate = new Date().toISOString().split("T")[0];
    this.userId = this.parseJwt(localStorage.getItem("token")).id;

    this.user =  await axios.request( {
          method: "get",
          maxBodyLength: Infinity,
          // strapi 3 filtering
          // https://docs-v3.strapi.io/developer-docs/latest/developer-resources/content-api/content-api.html#filters
          url: `${this.backend}/users/me`,
          headers: {
            Authorization: localStorage.getItem("token") || "",
          },
        });
    this.user = this.user.data;




    let config = {
      method: "get",
      maxBodyLength: Infinity,
      // strapi 3 filtering
      // https://docs-v3.strapi.io/developer-docs/latest/developer-resources/content-api/content-api.html#filters
      url: `${this.backend}/competitions?_where[0][competitionEndDate_lt]=${yourDate}&_where[1][referees.id_eq]=${this.userId}`,
      headers: {
        Authorization: localStorage.getItem("token") || "",
      },
    };

    axios
      .request(config)
      .then((response) => {
        this.competitions = response.data;
      })
      .catch((error) => {
        console.log(error);
      });


    let config2 = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${this.backend}/abrechnungsoption`,
      headers: {
        Authorization: localStorage.getItem("token") || "",
      },
    };


    axios
      .request(config2)
      .then((response) => {
        this.mileageAllowance = response.data.fahrtgeldEuroPerKilometer;
        this.refereeAllowance = response.data.pauschaleProSpielleiter;
        this.fahrtgeldEuroPerKilometer =  response.data.fahrtgeldEuroPerKilometer;
        this.pauschaleProSpielleiter =  response.data.pauschaleProSpielleiter;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  watch: {
    competition(newValue) {
      this.refreshCompetitionInfo(newValue);
    },
    comment(newValue) {
      this.comment = newValue
    },
    mileageAllowanceInput(newValue) {
      this.mileageAllowanceOutput = (this.mileageAllowance * newValue).toFixed(
        2
      );
      this.mileageAllowanceOutputAsString = this.mileageAllowanceOutput + " €";
    },
  },
  methods: {
    async refreshCompetitionInfo(competitionName) {
      this.competitions.forEach((element) => {
        if (element.name == competitionName) {
          // console.log("element", element);
          this.competitionId = element.id;
          this.competitionStartDate = element.competitionStartDate || null;
          this.competitionEndDate = element.competitionEndDate || null;
          if (element.referees && element.referees.length >= 1) {
            element.referees.forEach((referee) => {
              if (referee.id == this.userId) {
                this.username = referee.username;
                this.email = referee.email;
              }
            });
          }
        }
      });
      return null;
    },
    closeDialog() {
      this.dialog = false
      location.reload();
    },
    propsOnChange(selectedValue) {
      this.anzahlAnTagen = selectedValue;
      this.refereeAllowanceOutput = (
        this.refereeAllowance * selectedValue
      ).toFixed(2);
      this.refereeAllowanceOutputAsString = this.refereeAllowanceOutput + " €";
    },
    selectFile(file) {
      this.progress = 0;
      this.currentFile = file;
    },
    processImages(fileArray) {
      this.images = fileArray;
    },
    sendInvoiceForm() {
      if (this.images && this.images.length) {
        let formData = new FormData();
        for (let image of this.images) {
          if (image) formData.append("files", image, image.name);
        }

        axios
          .post(`${this.backend}/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: localStorage.getItem("token") || "",
            },
          })
          .then((response) => {
            axios
              .post(
                `${this.backend}/abrechnungens`,
                {
                  state: "gesendet",
                  invoiceAmount: this.betrag,
                  distanceDriven: this.mileageAllowanceInput || 0,
                  milageAllowance: this.mileageAllowanceOutput,
                  refereeAllowance: this.refereeAllowanceOutput || null,
                  anzahlAnTagen: this.anzahlAnTagen || 0,
                  fahrtgeldEuroPerKilometer: this.fahrtgeldEuroPerKilometer || 0,
                  pauschaleProSpielleiter: this.pauschaleProSpielleiter || 0,
                  sumExpenses: this.sumExpenses || 0,
                  processed: false,
                  comment: this.comment || "",
                  receipt: response.data,
                  users_permissions_user: this.userId,
                  competition: this.competitionId,
                  depositor: this.depositor,
                  bank: this.bank,
                  IBAN: this.iban,
                  BIC: this.bic,

                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token") || "",
                  },
                }
              )
              .then((response) => {
                if (response.status == 200) {
                  this.success = true
                  this.dialog = true
                } else {
                  this.success = false
                  this.dialog = true
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      else {
        axios
          .post(
            `${this.backend}/abrechnungens`,
            {
              state: "gesendet",
              invoiceAmount: this.betrag,
              distanceDriven: this.mileageAllowanceInput,
              milageAllowance: this.mileageAllowanceOutput,
              refereeAllowance: this.refereeAllowanceOutput || null,
              anzahlAnTagen: this.anzahlAnTagen || 0,
              fahrtgeldEuroPerKilometer: this.fahrtgeldEuroPerKilometer || 0,
              pauschaleProSpielleiter: this.pauschaleProSpielleiter || 0,
              sumExpenses: this.sumExpenses || 0,
              processed: false,
              comment: this.comment || "",
              receipt: null,
              users_permissions_user: this.userId,
              competition: this.competitionId,
              depositor: this.depositor,
              bank: this.bank,
              IBAN: this.iban,
              BIC: this.bic,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token") || "",
              },
            }
          )
          .then((response) => {
            if (response.status == 200) {
              this.success = true
              this.dialog = true
            } else {
              this.success = false
              this.dialog = true
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
  },
};
</script>
